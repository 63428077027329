import { component } from '../lib/components.js'

const EXPANDED_STATE = 1
const CLOSED_STATE = 0
const NOT_SELECTED = 0
const IS_SELECTED = 1
 
async function Tree (props) {
  this.state.tree ??= {}
  this.state.parsed ??= null

  let scrollTop = 0

  const walk = (nodes, fn) => {
    nodes = Array.isArray(nodes) ? nodes.slice() : [nodes]

    while (nodes.length) {
      const node = nodes.shift()
      const shouldBail = fn(node)
      if (shouldBail) {
        return shouldBail
      }

      if (node.children) {
        nodes.push(...node.children)
      }
    }
  }

  const getNodeFromElement = (el) => {
    const { path } = el.dataset

    if (!path) {
      return null
    }

    let parent = this.state.tree

    for (const position of path.split('.')) {
      if (parent && parent.children) {
        parent = parent.children[position]
      }
    }

    return parent
  }

  const resetSelectedNodeState = () => {
    walk(this.state.tree, (node) => {
      node.selected = NOT_SELECTED
    })
  }

  const resetLeafNodeState = () => {
    walk(this.state.tree, (node) => {
      if (node.children.length === 0) {
        node.state = CLOSED_STATE
      }
    })
  }

  const onscroll = () => {
    scrollTop = this.firstElementChild?.scrollTop
  }

  const onclick = (e, match) => {
    const el = match('[data-path]')
    if (!el) return

    if (e.detail === 2) {
      return
    }

    const node = getNodeFromElement(el)
    if (!node) getNodeFromElement(el.parentElement)
    if (!node) return

    const isIcon = match('.toggle')
    return clickNode(node, isIcon)
  }

  const onkeydown = async (e, match) => {
    if (e.keyCode === 32) {
      const focused = this.querySelector('a:focus')
      if (!focused) return

      const el = match(focused, '[data-path]')
      if (!el) return

      const node = getNodeFromElement(el)
      if (!node) return

      const { x, y } = focused.getBoundingClientRect()

      await clickNode(node, true)

      const newElement = document.elementFromPoint(x, y)
      if (newElement) newElement.focus()
    }
  }

  const onSelection = async (node, isToggle) => {
    if (!isToggle) {
      setTimeout(() => {
        document.body.removeAttribute('toc')
        node.element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
        window.location.hash = node.element.id
      }, 128)
    }
  }

  const parse = () => {
    // this.firstElementChild?.scrollTop = scrollTop

    const tree = {
      id: 'root',
      prec: 0,
      children: []
    }

    let headers = []
    let modules = Array.from(document.querySelectorAll('.collectable'))
    modules = Array.from(new Set(modules))
    console.log('MODULES', modules)

    for (const module of modules) {
      headers = [...headers, ...module.querySelectorAll('h1, h2, h3, h4')]
    }

    let parent = tree
    let sibling = 0

    for (const header of headers) {
      const prec = parseInt(header.tagName[1], 10)
      const method = /\.(\w+)\(/.exec(header.textContent)
      const title = method ? method[1] : header.textContent
      const selected = (header.id && header.id === decodeURIComponent(window.location.hash).slice(1)) ? 1 : 0

      if (selected) {
        let p = parent
        while (p) {
          p.state = 1
          p = p.parent
        }
      }

      const node = {
        id: header.id,
        path: [],
        element: header,
        parent,
        prec,
        selected,
        state: 0,
        label: title,
        children: []
      }

      const isCode = document.body.classList.contains('code')

      if (node.prec === 1) {
        node.icon = isCode ? 'package' : 'folder'
      } else {
        node.icon = 'file'
      }

      if (prec > sibling) {
        parent.children.push(node)
        node.element.id = (parent.element ? parent.element.textContent + '_' : '') + node.element.textContent
        // node.path.unshift(...(parent.path || []), parent.id)
        parent = node
      }

      if (prec === sibling && parent.parent) {
        if (parent.prec >= sibling) {
          parent = node.parent = parent.parent
        }
        parent.children.push(node)
        node.element.id = (parent.element ? parent.element.textContent + '_' : '') + node.element.textContent
        // node.path.unshift(...(parent.path || []), parent.id)
        parent = node
      }

      if (prec < sibling) {
        while (parent.prec >= prec) {
          parent = node.parent = parent.parent
        }
        parent.children.push(node)
        node.element.id = (parent.element ? parent.element.textContent + '_' : '') + node.element.textContent
        // node.path.unshift(...(parent.path || []), parent.id)
        parent = node
      }

      sibling = prec
    }

    return tree
  }

  const onready = () => {
    if (this.state.parsed === null) {
      setTimeout(() => {
        this.state.tree = parse()
        this.state.parsed = true
      })
      return
    }
  }

  const clickNode = async (node, isIcon, forceOpen) => {
    if (!node) return

    if (forceOpen) {
      node.state = CLOSED_STATE
    }

    if (isIcon) {
      if (node.state === EXPANDED_STATE) {
        node.state = CLOSED_STATE
      } else if (node.state === CLOSED_STATE) {
        node.state = EXPANDED_STATE
      }

      onSelection(node, true)
    } else {
      if (/* allowSelect && */ node.selected === NOT_SELECTED) {
        resetSelectedNodeState()
      }

      if (!node.children?.length && node.state === CLOSED_STATE) {
        resetLeafNodeState()
      }

      if (node.state === CLOSED_STATE) {
        node.state = EXPANDED_STATE
      }

      onSelection(node, false)

      if (!node.disabled) {
        node.selected = IS_SELECTED
        this.lastClickedNode = node
      }
    }

    await this.render()
    return node
  }

  const renderNode = (node, path = []) => {
    if (!node) return ''
    if (!node.children) return ''

    const children = []

    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i]
      const hasChildren = child.children && child.children.length

      // if (hasChildren) {
      //  children.push(this.renderNode(child.children))
      // }

      const isSelected = child.selected
      const title = (typeof child.title) === 'string' ? child.title : ''
      let icon = child.icon

      if (!icon || icon === 'folder') {
        icon = child.state === 1 ? 'folder-open' : 'folder'
      }

      const iconColor = node.iconColor || 'var(--x-primary)'

      let dragdrop = ''
      let classes = ''
      const childPath = [...path, i].join('.')

      if (props.dragdrop === true || props.dragdrop === 'true') {
        classes = 'draggable droppable'

        if (window.process.platform === 'linux') {
          dragdrop = 'draggable=true droppable=true'
        } else {
          dragdrop = `data-src="tree://${childPath}"`
        }
      }

      const hasToggle = hasChildren > 0 || (icon === 'folder')
      children.push(
        div({ class: 'item' },
          div({
            class: `handle ${classes}`,
            ...dragdrop,
            data: {
              dir: String(child.type !== 'file'),
              state: String(child.state),
              selected: String(isSelected),
              path: childPath,
              toggle: String(hasToggle)
            },
            title: title
          },
            hasToggle ? div({ class: 'toggle' }) : '',
            div({ class: 'region' },
              div({ class: 'node-data' },
                svg({ class: 'icon' },
                  use({ href: `/images/sprite.svg#${icon}` })
                ),
                div({ class: 'label', ...(child.disabled ? { disabled: '' } : {}) },
                  child.label
                ),
              )
            )
          ),
          hasChildren ? renderNode(child, [...path, i]) : ''
        )
      )
    }

    return div({ class: 'node' },
      div({ class: 'item' },
        ...children
      )
    )
  }

  // If this was already rendered in the document, remove it

  const tree = globalThis.window ? renderNode(this.state.tree) : ''

  return [
    onclick,
    onscroll,
    onkeydown,
    onready,
    tree 
  ]
}

export default component(Tree)
